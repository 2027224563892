var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.infoDialog,
            callback: function ($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" " + _vm._s(_vm.$t("Information")) + " "),
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.$t("NoAvailableSlots")) + " "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.infoDialog = false
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "dateDialog",
          attrs: { "return-value": _vm.date, persistent: "", width: "290px" },
          on: {
            "update:returnValue": function ($event) {
              _vm.date = $event
            },
            "update:return-value": function ($event) {
              _vm.date = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "mb-0 mt-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$store.state.appointment.appointment
                              .appDateString
                          )
                        ),
                      ]),
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "white--text",
                            attrs: {
                              disabled:
                                _vm.$store.state.appointment.appointment.address
                                  .location.lat === "",
                              color: "#198655",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.displayDatePickerIfPossible()
                              },
                            },
                          },
                          on
                        ),
                        [_vm._v(_vm._s(_vm.$t("ShopCalendar")))]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dateDialog,
            callback: function ($$v) {
              _vm.dateDialog = $$v
            },
            expression: "dateDialog",
          },
        },
        [
          _c(
            "v-card",
            { class: ["m-0", "p-0"], attrs: { id: "date-card" } },
            [
              _c(
                "v-card-text",
                { class: ["m-0", "p-0"], staticStyle: { padding: "0" } },
                [
                  _c(
                    "div",
                    [
                      !_vm.loadingAvailableSlots
                        ? _c(
                            "v-date-picker",
                            {
                              attrs: {
                                "allowed-dates": _vm.getAllowDates,
                                color: "#12945f",
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dateDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTimeComponent(_vm.date)
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.loadingAvailableSlots
                        ? _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { padding: "25px" },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 70,
                                  width: 5,
                                  color: "#12945f",
                                  indeterminate: "",
                                },
                              }),
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.$t("LoadingAvailableSlots"))),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "timeDialog",
          attrs: { "return-value": _vm.time, persistent: "", width: "360px" },
          on: {
            "update:returnValue": function ($event) {
              _vm.time = $event
            },
            "update:return-value": function ($event) {
              _vm.time = $event
            },
          },
          model: {
            value: _vm.timePickerDialog,
            callback: function ($$v) {
              _vm.timePickerDialog = $$v
            },
            expression: "timePickerDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", [
                  _c("i", { staticClass: "mdi mdi-clock" }),
                  _vm._v(" " + _vm._s(_vm.$t("AppointmentTime"))),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.hourRanges,
                      outlined: "",
                      chips: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.saveDateTime()
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }